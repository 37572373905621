import { Component } from 'react';
import ReactDOM from 'react-dom';

const portalRoot = typeof document !== `undefined` ? document.getElementById('___modal') : null;

export default class Portal extends Component {
  el: Element | null;

  constructor(props: any) {
    super(props);
    this.el = typeof document !== `undefined` ? document.createElement('div') : null;
  }

  componentDidMount = () => {
    if (!this.el) return;
    portalRoot?.appendChild(this.el);
  };

  componentWillUnmount = () => {
    if (!this.el) return;
    portalRoot?.removeChild(this.el);
  };

  render() {
    const { children } = this.props;

    if (this.el) {
      return ReactDOM.createPortal(children, this.el);
    } else {
      return null;
    }
  }
}
