import { graphql } from 'gatsby';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO';
import { SanityImage } from '../lib/types';
import Portal from '../components/Portal';

export const query = graphql`
  {
    gallery: allSanityGallery {
      nodes {
        id
        title
        image {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [WEBP, AUTO, JPG])
          }
        }
      }
    }
  }
`;

interface Props {
  data: {
    gallery: {
      nodes: { id: string; title: string; image: SanityImage }[];
    };
  };
}

const galerija: React.FC<Props> = ({ data }) => {
  const [modalImageIdx, setModalImageIdx] = useState<null | number>(null);

  function handleClose(e: React.MouseEvent) {
    if (e.target === e.currentTarget) {
      setModalImageIdx(null);
    }
  }

  return (
    <>
      <SEO title="Galerija" />
      <Grid>
        {data.gallery.nodes.map((x, index) => (
          <GridImage onClick={() => setModalImageIdx(index)} key={x.id}>
            <GatsbyImage class="grid-image-inner" image={x.image.asset.gatsbyImageData} alt={x.title} />
          </GridImage>
        ))}
      </Grid>
      {modalImageIdx !== null && (
        <Portal>
          <ImageModal onClick={handleClose}>
            {modalImageIdx > 0 ? (
              <GalleryButton onClick={() => setModalImageIdx(modalImageIdx - 1)}>❰</GalleryButton>
            ) : (
              <div />
            )}
            <div className="card">
              <GatsbyImage
                class="full-image"
                objectFit="contain"
                image={data.gallery.nodes[modalImageIdx].image.asset.gatsbyImageData}
                alt="full-image"
              />
            </div>
            {modalImageIdx < data.gallery.nodes.length - 1 ? (
              <GalleryButton onClick={() => setModalImageIdx(modalImageIdx + 1)}>❱</GalleryButton>
            ) : (
              <div />
            )}
          </ImageModal>
        </Portal>
      )}
    </>
  );
};

const ImageModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  backdrop-filter: blur(10px) brightness(0.5);

  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 10vw auto 10vw;

  animation: showImageModal 0.4s ease;
  @keyframes showImageModal {
    0% {
      backdrop-filter: blur(0px) brightness(1);
    }
    100% {
      backdrop-filter: blur(10px) brightness(0.5);
    }
  }

  .card {
    width: 75vw;
    max-height: 75vh;
  }

  .full-image {
    filter: drop-shadow(0 4px 24px rgba(0, 0, 0, 0.5));
    width: auto;
    max-width: 75vw;
    max-height: 75vh;

    @media (max-width: 800px) {
      height: auto;
    }

    animation: showImageModalCard 0.6s ease;
    @keyframes showImageModalCard {
      0% {
        transform: scale(0);
        opacity: 0;
      }
      50% {
        transform: scale(0);
        opacity: 0;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
`;

const Grid = styled.div`
  margin-bottom: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-auto-rows: 25vh;
  gap: 1rem;

  @media (max-width: 340px) {
    grid-template-columns: 1fr;
    grid-auto-rows: 20vh;
  }

  & > * {
    max-width: 100%;
  }
`;

const GridImage = styled.div`
  cursor: pointer;

  .grid-image-inner {
    height: 100%;
    width: 100%;
    border-radius: 4px;
  }
`;

const GalleryButton = styled.button`
  margin: 0 1rem;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  border-radius: 2px;
  padding: 0.4rem 0.8rem;
  font-size: 2rem;
  cursor: pointer;
  display: inline;

  @media (max-width: 800px) {
    font-size: 1rem;
    padding: 1rem 0.6rem;
  }
`;

export default galerija;
